import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "templateKey": "blog-post",
  "title": "Test Blog",
  "date": "2020-09-22T21:55:43.917Z",
  "description": "This is a test",
  "featuredpost": false,
  "featuredimage": "/img/products-grid3.jpg",
  "tags": ["test"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I’m testing this blog`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      